<template>
  <div
    id="app"
    :class="{ rotate: config.rotate }"
  >
    <h1
      v-if="missingParams.length"
      class="error"
    >
      Ontbrekende parameters: {{ missingParams.join(', ') }}
    </h1>
    <transition>
      <Component
        :is="`${currentSlide.type}-slide`"
        v-if="currentSlide"
        :key="currentSlide.id"
        class="slide"
        v-bind="currentSlide"
      />
      <img
        v-else
        :src="config.defaultImage"
        class="slide"
      >
    </transition>
  </div>
</template>

<script>
import io from 'socket.io-client'
import axios from 'axios'
import getConfig from "@/config";
import Transition from "@/components/transitions/Transition";

import ImagesSlide from "@/components/Image";
import VideoSlide from "@/components/Video";
import { initDB, saveSlides } from "@/utils/indexedDB";

const SUPPORTED_TYPES = ['images', 'video']
const REQUIRED_FIELDS = ['screen', 'screenId', 'station']

export default {
  name: 'App',
  components: {
    Transition,
    ImagesSlide,
    VideoSlide
  },
  data() {
    return {
      slides: [],
      config: getConfig(),
      currentSlide: null,
      nextIndex: 0,
      timeout: null,
      missingParams: [],
      socket: null,
    }
  },
  async mounted() {
    console.warn(navigator.userAgent)
    await initDB();
    this.checkRequiredConfig();

    if (!this.missingParams.length) {
      await this.fetchSlides();
      this.listenToRefreshWebsocket();
      this.startSlideShow();
    }
  },
  beforeDestroy() {
    window.clearTimeout(this.timeout);
    this.socket.close();
  },
  methods: {
    checkRequiredConfig() {
      REQUIRED_FIELDS.forEach((field) => {
        if (!this.config[field]) {
          this.missingParams.push(field);
        }
      });
    },
    async fetchSlides() {
      const response = await axios.get(this.config.apiEndpoints.screens)

      if (response.data?.items) {
        this.slides = response.data.items.filter((item) => SUPPORTED_TYPES.includes(item.type));

        saveSlides(this.slides)
      }
    },
    startSlideShow() {
      console.warn('start slideshow')
      if (this.slides.some((slide) => slide.loaded)) {
        this.nextSlide();
        return;
      }
      
      setTimeout(() => {
        console.warn('set timeout slideshow')
        this.startSlideShow()
      }, 200)
    },
    listenToRefreshWebsocket() {
      this.socket = io.connect(this.config.socketUrl)
      this.socket.on('reload', () => {
        location.reload();
      })
    },
    updateIndex() {
      this.nextIndex = (this.nextIndex + 1) % this.slides.length;
    },
    nextSlide() {
      clearTimeout(this.timeout);
      const nextSlide = this.slides[this.nextIndex];
      console.warn('next slide', JSON.stringify(nextSlide))
      this.updateIndex()

      if (!nextSlide.loaded) {
        return this.nextSlide()
      }

      this.currentSlide = nextSlide;

      if (this.nextIndex === 0) {
        this.fetchSlides();
      }

      this.timeout = setTimeout(() => {
        this.nextSlide()
      }, this.currentSlide.duration)
    }
  }
}
</script>

<style lang="scss">
#app {
  height: 100vh;
  width: 100vw;
  background-color: black;

  &.rotate {
    width: 100vh;
    height: 100vw;
    overflow: hidden;
    transform: rotate(-90deg) translateX(-100vh);
    transform-origin: top left;
  }
}

body {
  margin: 0;
}

html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  background-color: black;
  overflow: hidden;
}

.slide {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition-delay: 550ms;
}

.error {
  color: white;
  font-size: 20px;
  margin: 0;
}
</style>
